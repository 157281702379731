import payload_plugin_igcj_d8lNLPNoLfZoJBWffTnn4Z1i7xG9i1z7UuEBAc from "/srv/app/node_modules/.pnpm/@pinia+nuxt@0.10.1_magicast@0.3.5_pinia@3.0.1_typescript@5.8.2_vue@3.5.13_typescript@5.8.2__/node_modules/@pinia/nuxt/dist/runtime/payload-plugin.js";
import revive_payload_client_hd71o2SvTF82oeSi4qg_fSIz7sFkPwLs5BB6RwG_R1I from "/srv/app/node_modules/.pnpm/nuxt@3.16.1_@parcel+watcher@2.5.1_@types+node@22.13.14_better-sqlite3@11.9.1_db0@0.3.1__67ebc0edb26bc6ea6f6165bef7aeee39/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_bQ7uEwjYvv2an2R_FUTK0VYZDEr0R0ixQ4AHczl_Uhw from "/srv/app/node_modules/.pnpm/nuxt@3.16.1_@parcel+watcher@2.5.1_@types+node@22.13.14_better-sqlite3@11.9.1_db0@0.3.1__67ebc0edb26bc6ea6f6165bef7aeee39/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_70_kLWeYE3FSPIMyHddUkuqgwdo2hwoPBcFZvhXS5Dc from "/srv/app/node_modules/.pnpm/nuxt@3.16.1_@parcel+watcher@2.5.1_@types+node@22.13.14_better-sqlite3@11.9.1_db0@0.3.1__67ebc0edb26bc6ea6f6165bef7aeee39/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import _0_siteConfig_vf3V0_GKVjjYbIKNeA0rG1AlRg5hVtvRi_cAJgLpygo from "/srv/app/node_modules/.pnpm/nuxt-site-config@3.1.7_magicast@0.3.5_vue@3.5.13_typescript@5.8.2_/node_modules/nuxt-site-config/dist/runtime/app/plugins/0.siteConfig.js";
import payload_client_9Hh_IpoIig1ADxGoEF_3i56y2unbTycKzV_w3V40Jn8 from "/srv/app/node_modules/.pnpm/nuxt@3.16.1_@parcel+watcher@2.5.1_@types+node@22.13.14_better-sqlite3@11.9.1_db0@0.3.1__67ebc0edb26bc6ea6f6165bef7aeee39/node_modules/nuxt/dist/app/plugins/payload.client.js";
import navigation_repaint_client_JgKeVwUISg5L985C0jCaOaGFlljSbhK9_Y_5NpirzcI from "/srv/app/node_modules/.pnpm/nuxt@3.16.1_@parcel+watcher@2.5.1_@types+node@22.13.14_better-sqlite3@11.9.1_db0@0.3.1__67ebc0edb26bc6ea6f6165bef7aeee39/node_modules/nuxt/dist/app/plugins/navigation-repaint.client.js";
import check_outdated_build_client_IV3PZbhAkQCn6K8wHwftG6UPkMFkC40gA_mE4UOgkKw from "/srv/app/node_modules/.pnpm/nuxt@3.16.1_@parcel+watcher@2.5.1_@types+node@22.13.14_better-sqlite3@11.9.1_db0@0.3.1__67ebc0edb26bc6ea6f6165bef7aeee39/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import chunk_reload_client_tlL4QC_RGzxuvl_VyieholIKScho0FgIa_L35Xb7jLg from "/srv/app/node_modules/.pnpm/nuxt@3.16.1_@parcel+watcher@2.5.1_@types+node@22.13.14_better-sqlite3@11.9.1_db0@0.3.1__67ebc0edb26bc6ea6f6165bef7aeee39/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import plugin_vue3_65Sqd46dhOxirgBErFuGk_XAAlgjNDM5wMzIP9qiB8I from "/srv/app/node_modules/.pnpm/@pinia+nuxt@0.10.1_magicast@0.3.5_pinia@3.0.1_typescript@5.8.2_vue@3.5.13_typescript@5.8.2__/node_modules/@pinia/nuxt/dist/runtime/plugin.vue3.js";
import components_plugin_4kY4pyzJIYX99vmMAAIorFf3CnAaptHitJgf7JxiED8 from "/srv/app/src/node_modules/.cache/nuxt/.nuxt/components.plugin.mjs";
import prefetch_client_srhJ3yf2y3ZANBMwbbR4M9tEBAx2N6SxfUzabzuka00 from "/srv/app/node_modules/.pnpm/nuxt@3.16.1_@parcel+watcher@2.5.1_@types+node@22.13.14_better-sqlite3@11.9.1_db0@0.3.1__67ebc0edb26bc6ea6f6165bef7aeee39/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import plugin_client_DpaUXUKNGzPQPM7Ta5idOPt5Af3WiiVJGKLlhlriW28 from "/srv/app/node_modules/.pnpm/nuxt-gtag@3.0.2_magicast@0.3.5/node_modules/nuxt-gtag/dist/runtime/plugin.client.js";
import pwa_icons_plugin_OtOZ6CGly_Vz5_PCGGLA9qHLz2Y5_d5czYAX7q_3Lug from "/srv/app/src/node_modules/.cache/nuxt/.nuxt/pwa-icons-plugin.ts";
import siteConfig_GpqXPo_M_DuLFPBLI4zIVBXxAV_kSymiVHMWgxe54Mo from "/srv/app/node_modules/.pnpm/nuxt-seo-utils@7.0.4_magicast@0.3.5_rollup@4.38.0_vue@3.5.13_typescript@5.8.2_/node_modules/nuxt-seo-utils/dist/runtime/app/plugins/siteConfig.js";
import inferSeoMetaPlugin_6IO37_7vKHm1yH3a7ApkFlizBD07hCcItiuZ7Kussrk from "/srv/app/node_modules/.pnpm/nuxt-seo-utils@7.0.4_magicast@0.3.5_rollup@4.38.0_vue@3.5.13_typescript@5.8.2_/node_modules/nuxt-seo-utils/dist/runtime/app/plugins/inferSeoMetaPlugin.js";
import titles_WZsvaGNbxpzuixcKv_SH2OLy6eFlY8TdxEHuecVH7_o from "/srv/app/node_modules/.pnpm/nuxt-seo-utils@7.0.4_magicast@0.3.5_rollup@4.38.0_vue@3.5.13_typescript@5.8.2_/node_modules/nuxt-seo-utils/dist/runtime/app/plugins/titles.js";
import defaultsWaitI18n_3UQGFyPsP6ZnodVN6PRmL0cSrYVculhVoBapizuvRHI from "/srv/app/node_modules/.pnpm/nuxt-seo-utils@7.0.4_magicast@0.3.5_rollup@4.38.0_vue@3.5.13_typescript@5.8.2_/node_modules/nuxt-seo-utils/dist/runtime/app/plugins/defaultsWaitI18n.js";
import i18n_HW1fSVXXQ9Vb__Fu6Rhzdv3Gig6qrEzxApHtcOnqZhQ from "/srv/app/node_modules/.pnpm/nuxt-site-config@3.1.7_magicast@0.3.5_vue@3.5.13_typescript@5.8.2_/node_modules/nuxt-site-config/dist/runtime/app/plugins/i18n.js";
import switch_locale_path_ssr_H73XrUS4BT4TCa_R2BsyVuGiuqOjxHD4kEIryNkCeww from "/srv/app/node_modules/.pnpm/@nuxtjs+i18n@9.4.0_@vue+compiler-dom@3.5.13_eslint@9.23.0_jiti@2.4.2__magicast@0.3.5_ro_6ea16ffbe0833a8c062373776f583455/node_modules/@nuxtjs/i18n/dist/runtime/plugins/switch-locale-path-ssr.js";
import route_locale_detect_MbU2xXjTfPewKuXfqH8WASv7ewFFe7B08ejCWUY0UrU from "/srv/app/node_modules/.pnpm/@nuxtjs+i18n@9.4.0_@vue+compiler-dom@3.5.13_eslint@9.23.0_jiti@2.4.2__magicast@0.3.5_ro_6ea16ffbe0833a8c062373776f583455/node_modules/@nuxtjs/i18n/dist/runtime/plugins/route-locale-detect.js";
import i18n_Cn8slKYvjd4hUEbmQaPPb1Gha6hpG2VLvmomBMuHcnI from "/srv/app/node_modules/.pnpm/@nuxtjs+i18n@9.4.0_@vue+compiler-dom@3.5.13_eslint@9.23.0_jiti@2.4.2__magicast@0.3.5_ro_6ea16ffbe0833a8c062373776f583455/node_modules/@nuxtjs/i18n/dist/runtime/plugins/i18n.js";
import plugin_client_sQsoN3ShCY3w7TTUhDo6ZgZURKOa9c7Ot_XTJsCGlO0 from "/srv/app/node_modules/.pnpm/@nuxtjs+color-mode@3.5.2_magicast@0.3.5/node_modules/@nuxtjs/color-mode/dist/runtime/plugin.client.js";
import plugin_umR8icOG0AgNIYuQUTs86p7vHGFln7l8AUOF3W8khCk from "/srv/app/node_modules/.pnpm/@dargmuesli+nuxt-cookie-control@8.6.1_magicast@0.3.5_webpack@5.98.0_esbuild@0.25.2_/node_modules/@dargmuesli/nuxt-cookie-control/dist/runtime/plugin.js";
import sentry_client_2CXEZHIu0FKIDMrWsOvSE6itLQkFVQG24wjvxMNOmZ0 from "/srv/app/node_modules/.pnpm/@sentry+nuxt@9.10.1_@opentelemetry+api@1.9.0_@opentelemetry+context-async-hooks@1.30.1__84230e5b90a2798f2a5f220ea820b752/node_modules/@sentry/nuxt/build/module/runtime/plugins/sentry.client.js";
import sentry_client_config_wjhrcCL76ies_5saPwWOCD7XHMK0wr7fih8C3HPfjCo from "/srv/app/src/node_modules/.cache/nuxt/.nuxt/sentry-client-config.mjs";
import dayjs_p2OKE00uxZOw__7bPvNteaMaTW1bYCED15GMqLobemA from "/srv/app/src/app/plugins/dayjs.ts";
import i18n_rgpBWqceGhUXVUoLOJ2oM3jvlmYalZprXt7Hyqw4ZVg from "/srv/app/src/app/plugins/i18n.ts";
import notification_client_v_6RuAjrzGU2j110j4QCAo5glu_LLfwrBOrzDQtV1n8 from "/srv/app/src/app/plugins/notification.client.ts";
import urql_t9p8JIAfp3hNTh1f_FERMYwuXgMlho9pnMHB3mefCnE from "/srv/app/src/app/plugins/urql.ts";
import pwa_client_kyKCdWdIqI3rv1SAK3z9dWVm7jlejIb2BWhprc0mPl4 from "/srv/app/node_modules/.pnpm/@vite-pwa+nuxt@1.0.0_magicast@0.3.5_vite@6.2.3_@types+node@22.13.14_jiti@2.4.2_lightnin_3f8ab3327afd0cdd17a297938e94cbfb/node_modules/@vite-pwa/nuxt/dist/runtime/plugins/pwa.client.js";
import _0_routeRules_ZGALmNU8bmZ0u_Kmd_xGih3uiHjFflBAphZ9yQI0cm8 from "/srv/app/node_modules/.pnpm/nuxt-seo-utils@7.0.4_magicast@0.3.5_rollup@4.38.0_vue@3.5.13_typescript@5.8.2_/node_modules/nuxt-seo-utils/dist/runtime/app/plugins/0.routeRules.js";
import ssg_detect_xhjIrE0fdfVlWjrKBddP1Gix1OsOCNPRyNLGtPNhaW0 from "/srv/app/node_modules/.pnpm/@nuxtjs+i18n@9.4.0_@vue+compiler-dom@3.5.13_eslint@9.23.0_jiti@2.4.2__magicast@0.3.5_ro_6ea16ffbe0833a8c062373776f583455/node_modules/@nuxtjs/i18n/dist/runtime/plugins/ssg-detect.js";
export default [
  payload_plugin_igcj_d8lNLPNoLfZoJBWffTnn4Z1i7xG9i1z7UuEBAc,
  revive_payload_client_hd71o2SvTF82oeSi4qg_fSIz7sFkPwLs5BB6RwG_R1I,
  unhead_bQ7uEwjYvv2an2R_FUTK0VYZDEr0R0ixQ4AHczl_Uhw,
  router_70_kLWeYE3FSPIMyHddUkuqgwdo2hwoPBcFZvhXS5Dc,
  _0_siteConfig_vf3V0_GKVjjYbIKNeA0rG1AlRg5hVtvRi_cAJgLpygo,
  payload_client_9Hh_IpoIig1ADxGoEF_3i56y2unbTycKzV_w3V40Jn8,
  navigation_repaint_client_JgKeVwUISg5L985C0jCaOaGFlljSbhK9_Y_5NpirzcI,
  check_outdated_build_client_IV3PZbhAkQCn6K8wHwftG6UPkMFkC40gA_mE4UOgkKw,
  chunk_reload_client_tlL4QC_RGzxuvl_VyieholIKScho0FgIa_L35Xb7jLg,
  plugin_vue3_65Sqd46dhOxirgBErFuGk_XAAlgjNDM5wMzIP9qiB8I,
  components_plugin_4kY4pyzJIYX99vmMAAIorFf3CnAaptHitJgf7JxiED8,
  prefetch_client_srhJ3yf2y3ZANBMwbbR4M9tEBAx2N6SxfUzabzuka00,
  plugin_client_DpaUXUKNGzPQPM7Ta5idOPt5Af3WiiVJGKLlhlriW28,
  pwa_icons_plugin_OtOZ6CGly_Vz5_PCGGLA9qHLz2Y5_d5czYAX7q_3Lug,
  siteConfig_GpqXPo_M_DuLFPBLI4zIVBXxAV_kSymiVHMWgxe54Mo,
  inferSeoMetaPlugin_6IO37_7vKHm1yH3a7ApkFlizBD07hCcItiuZ7Kussrk,
  titles_WZsvaGNbxpzuixcKv_SH2OLy6eFlY8TdxEHuecVH7_o,
  defaultsWaitI18n_3UQGFyPsP6ZnodVN6PRmL0cSrYVculhVoBapizuvRHI,
  i18n_HW1fSVXXQ9Vb__Fu6Rhzdv3Gig6qrEzxApHtcOnqZhQ,
  switch_locale_path_ssr_H73XrUS4BT4TCa_R2BsyVuGiuqOjxHD4kEIryNkCeww,
  route_locale_detect_MbU2xXjTfPewKuXfqH8WASv7ewFFe7B08ejCWUY0UrU,
  i18n_Cn8slKYvjd4hUEbmQaPPb1Gha6hpG2VLvmomBMuHcnI,
  plugin_client_sQsoN3ShCY3w7TTUhDo6ZgZURKOa9c7Ot_XTJsCGlO0,
  plugin_umR8icOG0AgNIYuQUTs86p7vHGFln7l8AUOF3W8khCk,
  sentry_client_2CXEZHIu0FKIDMrWsOvSE6itLQkFVQG24wjvxMNOmZ0,
  sentry_client_config_wjhrcCL76ies_5saPwWOCD7XHMK0wr7fih8C3HPfjCo,
  dayjs_p2OKE00uxZOw__7bPvNteaMaTW1bYCED15GMqLobemA,
  i18n_rgpBWqceGhUXVUoLOJ2oM3jvlmYalZprXt7Hyqw4ZVg,
  notification_client_v_6RuAjrzGU2j110j4QCAo5glu_LLfwrBOrzDQtV1n8,
  urql_t9p8JIAfp3hNTh1f_FERMYwuXgMlho9pnMHB3mefCnE,
  pwa_client_kyKCdWdIqI3rv1SAK3z9dWVm7jlejIb2BWhprc0mPl4,
  _0_routeRules_ZGALmNU8bmZ0u_Kmd_xGih3uiHjFflBAphZ9yQI0cm8,
  ssg_detect_xhjIrE0fdfVlWjrKBddP1Gix1OsOCNPRyNLGtPNhaW0
]