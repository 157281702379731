
if (import.meta.hot) {
  import.meta.hot.accept((mod) => {
    const router = import.meta.hot.data.router
    const generateRoutes = import.meta.hot.data.generateRoutes
    if (!router || !generateRoutes) {
      import.meta.hot.invalidate('[nuxt] Cannot replace routes because there is no active router. Reloading.')
      return
    }
    router.clearRoutes()
    const routes = generateRoutes(mod.default || mod)
    function addRoutes (routes) {
      for (const route of routes) {
        router.addRoute(route)
      }
      router.replace(router.currentRoute.value.fullPath)
    }
    if (routes && 'then' in routes) {
      routes.then(addRoutes)
    } else {
      addRoutes(routes)
    }
  })
}

export function handleHotUpdate(_router, _generateRoutes) {
  if (import.meta.hot) {
    import.meta.hot.data ||= {}
    import.meta.hot.data.router = _router
    import.meta.hot.data.generateRoutes = _generateRoutes
  }
}
import { default as indexER3CPX4k2x3RnO2ZZIIOslci0PkDGVCnBlQR2Hx_WRkMeta } from "/srv/app/src/app/pages/index.vue?macro=true";
import { default as indexvcqSWz2G_vqcy05WDJpwM_UQdTEocdgbMfnSdKnviqUMeta } from "/srv/app/src/app/pages/🫖/index.vue?macro=true";
import { default as appWDA5uiVjsxhYX_45v9Hx_ZKSc1h_456zmkvUCcSks1Di4UcMeta } from "/srv/app/src/app/pages/docs/app.vue?macro=true";
import { default as indexsUDvQYL83LzG6l2IhMMKNsXHwehZ0Tz9vGdE_45KjKwhQMeta } from "/srv/app/src/app/pages/event/index.vue?macro=true";
import { default as indexMJMscsrjNq6R46NEwMLjAHppaT84Qtj_45Eo0mWXTSPqwMeta } from "/srv/app/src/app/pages/guest/index.vue?macro=true";
import { default as createw1YAlrhbGVp0ar3bhJhe654PG_456xI_6c45dDEcULIHsMeta } from "/srv/app/src/app/pages/event/create.vue?macro=true";
import { default as unlock_6kJPOOnIA3CaMPOBKrLF4PRS4szq4lk1xN_xmf94cUMeta } from "/srv/app/src/app/pages/guest/unlock.vue?macro=true";
import { default as indexuthzOsGZ78NvUC0_45VBQ58a9VzyoutebU0WzM8Ac5bokMeta } from "/srv/app/src/app/pages/upload/index.vue?macro=true";
import { default as index5DL9ey4e3w2wGRgeZCbrETRT7pTJpteGGNctUWbtImIMeta } from "/srv/app/src/app/pages/account/index.vue?macro=true";
import { default as indexOeLeVeVtucZqrq3QUPXpBtYa4JPRIlzWYtnNFrvpexQMeta } from "/srv/app/src/app/pages/contact/index.vue?macro=true";
import { default as createJoc8L4nE70LmUrSj7Cfwwn9CYwODkFb0bKPSFHGSdVwMeta } from "/srv/app/src/app/pages/account/create.vue?macro=true";
import { default as verify1wYB7h0EKDeJIvA8dCmYIUC9ABCxLU2J4enWRWWXz7MMeta } from "/srv/app/src/app/pages/account/verify.vue?macro=true";
import { default as createO91cQlD7b0C3WGQoQgJGsAdMj4JFhlHaXp_sn8gP_45uAMeta } from "/srv/app/src/app/pages/session/create.vue?macro=true";
import { default as indexuqBS_LwxyMa5f9bGdRolDCLlkg89EgPN9TVzxGfHK1wMeta } from "/srv/app/src/app/pages/dashboard/index.vue?macro=true";
import { default as indexJa8yL6DS84LOcqTsyKafev2gK_nmkcpl3YG74ZARkT4Meta } from "/srv/app/src/app/pages/attendance/index.vue?macro=true";
import { default as urlquSMpIAaO1vAQ4xXdRmQCPLuejXHCPfYLjK192RL49sMeta } from "/srv/app/src/app/pages/event/ingest/url.vue?macro=true";
import { default as _91id_93jO47l0O4yhhuE5FGbJVRn0NWFCC2gVTIQUvY09aVOxwMeta } from "/srv/app/src/app/pages/session/view/[id].vue?macro=true";
import { default as imageP399l_45fPvG95ktoqd1u3QBqDIEogHZqMF6GCxBFy_RoMeta } from "/srv/app/src/app/pages/event/ingest/image.vue?macro=true";
import { default as indexGv8UE348TN4gZffdsKDjoYhNEH5fWGu8gJpop9pQGBQMeta } from "/srv/app/src/app/pages/legal-notice/index.vue?macro=true";
import { default as indexbh_45wOtwz_45Y40vqyk3wjluvdlg3AxjBGXec9kwc3sUkIMeta } from "/srv/app/src/app/pages/notification/index.vue?macro=true";
import { default as browser_45supportDX4VxNQOfWYPKhVPMHsNr8oPxtPpvQvwkEwcrf2BjpAMeta } from "/srv/app/src/app/pages/docs/browser-support.vue?macro=true";
import { default as indexmWz18iNfySbM8F_sORa_45F_APfugECZ1Vqvq46wjTwPAMeta } from "/srv/app/src/app/pages/privacy-policy/index.vue?macro=true";
import { default as _91username_93S9NLGZlWKA_78K_45UBavQ0vtcc4_45a5n5nWz459Qf5xBMMeta } from "/srv/app/src/app/pages/account/edit/[username].vue?macro=true";
import { default as _91username_93c1zzP7M1_45EFHIkRsBJiX_45Q7c6CQwt0e56jsLT_45e3yK4Meta } from "/srv/app/src/app/pages/account/view/[username].vue?macro=true";
import { default as indexJJEZnf1vPz9WcJjxiWVlqNgwBbd8BEpUYzC0Bd6Il_MMeta } from "/srv/app/src/app/pages/session/edit/[id]/index.vue?macro=true";
import { default as indexCaJNHcPWjU65j9YZ8SY4MM5swYJArSSSIV_45grhLy5_454Meta } from "/srv/app/src/app/pages/legal/attributions/index.vue?macro=true";
import { default as languagejOw6nE72tZ_45KqHk4HFh1aBD3iDQikaIT43UjjFmJZWcMeta } from "/srv/app/src/app/pages/session/edit/[id]/language.vue?macro=true";
import { default as index4HKofKg6OsBoI2LvUH_IidSrA37qP4DmwXQOZL7VvMwMeta } from "/srv/app/src/app/pages/event/view/[username]/index.vue?macro=true";
import { default as indexRIS8YpqdKsy057kMMjq_G0S0WEEMxWqDLvfobvd1XUEMeta } from "/srv/app/src/app/pages/account/password/reset/index.vue?macro=true";
import { default as requestd0bF26Yr9ZaxONuXpvWWQVGvmkoCReAcC7W0I5MIBAMMeta } from "/srv/app/src/app/pages/account/password/reset/request.vue?macro=true";
import { default as color_45schemeQgiZT0pv_45S0dGLd0h_CjHMcUTohmeYmWtUl_45QgoTnRkMeta } from "/srv/app/src/app/pages/session/edit/[id]/color-scheme.vue?macro=true";
import { default as _91event_name_93mULKmiXhiQXKyTeboASDkiIGMWR_pBWiZw6tTMBOGv4Meta } from "/srv/app/src/app/pages/event/edit/[username]/[event_name].vue?macro=true";
import { default as guest6Q_45rCK7eNYlMkSHaqQFYqiZLpcwjK7LGKKRd2Ny8YBAMeta } from "/srv/app/src/app/pages/event/view/[username]/[event_name]/guest.vue?macro=true";
import { default as indexGCt_45u8Ac3uEQTE1mAvB0Imek_456vy1UZ7gml1g_45l4q_IMeta } from "/srv/app/src/app/pages/event/view/[username]/[event_name]/index.vue?macro=true";
import { default as attendancewQo_45fNAIper5KiA7RffZQalDMRDDnQ0JJfSK1nMqjvMMeta } from "/srv/app/src/app/pages/event/view/[username]/[event_name]/attendance.vue?macro=true";
import { default as component_45stubKi_45R4nsUaY4MPp_45b5Z_DjMpRGVKilt25GMPmN8PSnRUMeta } from "/srv/app/node_modules/.pnpm/nuxt@3.16.1_@parcel+watcher@2.5.1_@types+node@22.13.14_better-sqlite3@11.9.1_db0@0.3.1__67ebc0edb26bc6ea6f6165bef7aeee39/node_modules/nuxt/dist/pages/runtime/component-stub.js?macro=true";
import { default as component_45stubKi_45R4nsUaY4MPp_45b5Z_DjMpRGVKilt25GMPmN8PSnRU } from "/srv/app/node_modules/.pnpm/nuxt@3.16.1_@parcel+watcher@2.5.1_@types+node@22.13.14_better-sqlite3@11.9.1_db0@0.3.1__67ebc0edb26bc6ea6f6165bef7aeee39/node_modules/nuxt/dist/pages/runtime/component-stub.js";
export default [
  {
    name: "index___de",
    path: "/de",
    component: () => import("/srv/app/src/app/pages/index.vue")
  },
  {
    name: "index___en",
    path: "/",
    component: () => import("/srv/app/src/app/pages/index.vue")
  },
  {
    name: "🫖___de",
    path: "/de/%F0%9F%AB%96",
    component: () => import("/srv/app/src/app/pages/🫖/index.vue")
  },
  {
    name: "🫖___en",
    path: "/%F0%9F%AB%96",
    component: () => import("/srv/app/src/app/pages/🫖/index.vue")
  },
  {
    name: "docs-app___de",
    path: "/de/docs/app",
    component: () => import("/srv/app/src/app/pages/docs/app.vue")
  },
  {
    name: "docs-app___en",
    path: "/docs/app",
    component: () => import("/srv/app/src/app/pages/docs/app.vue")
  },
  {
    name: "event___de",
    path: "/de/event",
    component: () => import("/srv/app/src/app/pages/event/index.vue")
  },
  {
    name: "event___en",
    path: "/event",
    component: () => import("/srv/app/src/app/pages/event/index.vue")
  },
  {
    name: "guest___de",
    path: "/de/guest",
    component: () => import("/srv/app/src/app/pages/guest/index.vue")
  },
  {
    name: "guest___en",
    path: "/guest",
    component: () => import("/srv/app/src/app/pages/guest/index.vue")
  },
  {
    name: "event-create___de",
    path: "/de/event/create",
    component: () => import("/srv/app/src/app/pages/event/create.vue")
  },
  {
    name: "event-create___en",
    path: "/event/create",
    component: () => import("/srv/app/src/app/pages/event/create.vue")
  },
  {
    name: "guest-unlock___de",
    path: "/de/guest/unlock",
    meta: unlock_6kJPOOnIA3CaMPOBKrLF4PRS4szq4lk1xN_xmf94cUMeta || {},
    component: () => import("/srv/app/src/app/pages/guest/unlock.vue")
  },
  {
    name: "guest-unlock___en",
    path: "/guest/unlock",
    meta: unlock_6kJPOOnIA3CaMPOBKrLF4PRS4szq4lk1xN_xmf94cUMeta || {},
    component: () => import("/srv/app/src/app/pages/guest/unlock.vue")
  },
  {
    name: "upload___de",
    path: "/de/upload",
    component: () => import("/srv/app/src/app/pages/upload/index.vue")
  },
  {
    name: "upload___en",
    path: "/upload",
    component: () => import("/srv/app/src/app/pages/upload/index.vue")
  },
  {
    name: "account___de",
    path: "/de/account",
    component: () => import("/srv/app/src/app/pages/account/index.vue")
  },
  {
    name: "account___en",
    path: "/account",
    component: () => import("/srv/app/src/app/pages/account/index.vue")
  },
  {
    name: "contact___de",
    path: "/de/contact",
    component: () => import("/srv/app/src/app/pages/contact/index.vue")
  },
  {
    name: "contact___en",
    path: "/contact",
    component: () => import("/srv/app/src/app/pages/contact/index.vue")
  },
  {
    name: "account-create___de",
    path: "/de/account/create",
    component: () => import("/srv/app/src/app/pages/account/create.vue")
  },
  {
    name: "account-create___en",
    path: "/account/create",
    component: () => import("/srv/app/src/app/pages/account/create.vue")
  },
  {
    name: "account-verify___de",
    path: "/de/account/verify",
    component: () => import("/srv/app/src/app/pages/account/verify.vue")
  },
  {
    name: "account-verify___en",
    path: "/account/verify",
    component: () => import("/srv/app/src/app/pages/account/verify.vue")
  },
  {
    name: "session-create___de",
    path: "/de/session/create",
    component: () => import("/srv/app/src/app/pages/session/create.vue")
  },
  {
    name: "session-create___en",
    path: "/session/create",
    component: () => import("/srv/app/src/app/pages/session/create.vue")
  },
  {
    name: "dashboard___de",
    path: "/de/dashboard",
    component: () => import("/srv/app/src/app/pages/dashboard/index.vue")
  },
  {
    name: "dashboard___en",
    path: "/dashboard",
    component: () => import("/srv/app/src/app/pages/dashboard/index.vue")
  },
  {
    name: "attendance___de",
    path: "/de/attendance",
    component: () => import("/srv/app/src/app/pages/attendance/index.vue")
  },
  {
    name: "attendance___en",
    path: "/attendance",
    component: () => import("/srv/app/src/app/pages/attendance/index.vue")
  },
  {
    name: "event-ingest-url___de",
    path: "/de/event/ingest/url",
    meta: urlquSMpIAaO1vAQ4xXdRmQCPLuejXHCPfYLjK192RL49sMeta || {},
    component: () => import("/srv/app/src/app/pages/event/ingest/url.vue")
  },
  {
    name: "event-ingest-url___en",
    path: "/event/ingest/url",
    meta: urlquSMpIAaO1vAQ4xXdRmQCPLuejXHCPfYLjK192RL49sMeta || {},
    component: () => import("/srv/app/src/app/pages/event/ingest/url.vue")
  },
  {
    name: "session-view-id___de",
    path: "/de/session/view/:id()",
    component: () => import("/srv/app/src/app/pages/session/view/[id].vue")
  },
  {
    name: "session-view-id___en",
    path: "/session/view/:id()",
    component: () => import("/srv/app/src/app/pages/session/view/[id].vue")
  },
  {
    name: "event-ingest-image___de",
    path: "/de/event/ingest/image",
    component: () => import("/srv/app/src/app/pages/event/ingest/image.vue")
  },
  {
    name: "event-ingest-image___en",
    path: "/event/ingest/image",
    component: () => import("/srv/app/src/app/pages/event/ingest/image.vue")
  },
  {
    name: "legal-notice___de",
    path: "/de/legal-notice",
    component: () => import("/srv/app/src/app/pages/legal-notice/index.vue")
  },
  {
    name: "legal-notice___en",
    path: "/legal-notice",
    component: () => import("/srv/app/src/app/pages/legal-notice/index.vue")
  },
  {
    name: "notification___de",
    path: "/de/notification",
    component: () => import("/srv/app/src/app/pages/notification/index.vue")
  },
  {
    name: "notification___en",
    path: "/notification",
    component: () => import("/srv/app/src/app/pages/notification/index.vue")
  },
  {
    name: "docs-browser-support___de",
    path: "/de/docs/browser-support",
    component: () => import("/srv/app/src/app/pages/docs/browser-support.vue")
  },
  {
    name: "docs-browser-support___en",
    path: "/docs/browser-support",
    component: () => import("/srv/app/src/app/pages/docs/browser-support.vue")
  },
  {
    name: "privacy-policy___de",
    path: "/de/privacy-policy",
    component: () => import("/srv/app/src/app/pages/privacy-policy/index.vue")
  },
  {
    name: "privacy-policy___en",
    path: "/privacy-policy",
    component: () => import("/srv/app/src/app/pages/privacy-policy/index.vue")
  },
  {
    name: "account-edit-username___de",
    path: "/de/account/edit/:username()",
    component: () => import("/srv/app/src/app/pages/account/edit/[username].vue")
  },
  {
    name: "account-edit-username___en",
    path: "/account/edit/:username()",
    component: () => import("/srv/app/src/app/pages/account/edit/[username].vue")
  },
  {
    name: "account-view-username___de",
    path: "/de/account/view/:username()",
    component: () => import("/srv/app/src/app/pages/account/view/[username].vue")
  },
  {
    name: "account-view-username___en",
    path: "/account/view/:username()",
    component: () => import("/srv/app/src/app/pages/account/view/[username].vue")
  },
  {
    name: "session-edit-id___de",
    path: "/de/session/edit/:id()",
    component: () => import("/srv/app/src/app/pages/session/edit/[id]/index.vue")
  },
  {
    name: "session-edit-id___en",
    path: "/session/edit/:id()",
    component: () => import("/srv/app/src/app/pages/session/edit/[id]/index.vue")
  },
  {
    name: "legal-attributions___de",
    path: "/de/legal/attributions",
    component: () => import("/srv/app/src/app/pages/legal/attributions/index.vue")
  },
  {
    name: "legal-attributions___en",
    path: "/legal/attributions",
    component: () => import("/srv/app/src/app/pages/legal/attributions/index.vue")
  },
  {
    name: "session-edit-id-language___de",
    path: "/de/session/edit/:id()/language",
    component: () => import("/srv/app/src/app/pages/session/edit/[id]/language.vue")
  },
  {
    name: "session-edit-id-language___en",
    path: "/session/edit/:id()/language",
    component: () => import("/srv/app/src/app/pages/session/edit/[id]/language.vue")
  },
  {
    name: "event-view-username___de",
    path: "/de/event/view/:username()",
    component: () => import("/srv/app/src/app/pages/event/view/[username]/index.vue")
  },
  {
    name: "event-view-username___en",
    path: "/event/view/:username()",
    component: () => import("/srv/app/src/app/pages/event/view/[username]/index.vue")
  },
  {
    name: "account-password-reset___de",
    path: "/de/account/password/reset",
    component: () => import("/srv/app/src/app/pages/account/password/reset/index.vue")
  },
  {
    name: "account-password-reset___en",
    path: "/account/password/reset",
    component: () => import("/srv/app/src/app/pages/account/password/reset/index.vue")
  },
  {
    name: "account-password-reset-request___de",
    path: "/de/account/password/reset/request",
    component: () => import("/srv/app/src/app/pages/account/password/reset/request.vue")
  },
  {
    name: "account-password-reset-request___en",
    path: "/account/password/reset/request",
    component: () => import("/srv/app/src/app/pages/account/password/reset/request.vue")
  },
  {
    name: "session-edit-id-color-scheme___de",
    path: "/de/session/edit/:id()/color-scheme",
    component: () => import("/srv/app/src/app/pages/session/edit/[id]/color-scheme.vue")
  },
  {
    name: "session-edit-id-color-scheme___en",
    path: "/session/edit/:id()/color-scheme",
    component: () => import("/srv/app/src/app/pages/session/edit/[id]/color-scheme.vue")
  },
  {
    name: "event-edit-username-event_name___de",
    path: "/de/event/edit/:username()/:event_name()",
    component: () => import("/srv/app/src/app/pages/event/edit/[username]/[event_name].vue")
  },
  {
    name: "event-edit-username-event_name___en",
    path: "/event/edit/:username()/:event_name()",
    component: () => import("/srv/app/src/app/pages/event/edit/[username]/[event_name].vue")
  },
  {
    name: "event-view-username-event_name-guest___de",
    path: "/de/event/view/:username()/:event_name()/guest",
    component: () => import("/srv/app/src/app/pages/event/view/[username]/[event_name]/guest.vue")
  },
  {
    name: "event-view-username-event_name-guest___en",
    path: "/event/view/:username()/:event_name()/guest",
    component: () => import("/srv/app/src/app/pages/event/view/[username]/[event_name]/guest.vue")
  },
  {
    name: "event-view-username-event_name___de",
    path: "/de/event/view/:username()/:event_name()",
    component: () => import("/srv/app/src/app/pages/event/view/[username]/[event_name]/index.vue")
  },
  {
    name: "event-view-username-event_name___en",
    path: "/event/view/:username()/:event_name()",
    component: () => import("/srv/app/src/app/pages/event/view/[username]/[event_name]/index.vue")
  },
  {
    name: "event-view-username-event_name-attendance___de",
    path: "/de/event/view/:username()/:event_name()/attendance",
    component: () => import("/srv/app/src/app/pages/event/view/[username]/[event_name]/attendance.vue")
  },
  {
    name: "event-view-username-event_name-attendance___en",
    path: "/event/view/:username()/:event_name()/attendance",
    component: () => import("/srv/app/src/app/pages/event/view/[username]/[event_name]/attendance.vue")
  },
  {
    name: component_45stubKi_45R4nsUaY4MPp_45b5Z_DjMpRGVKilt25GMPmN8PSnRUMeta?.name,
    path: "/de/sitemap.xml",
    component: component_45stubKi_45R4nsUaY4MPp_45b5Z_DjMpRGVKilt25GMPmN8PSnRU
  },
  {
    name: component_45stubKi_45R4nsUaY4MPp_45b5Z_DjMpRGVKilt25GMPmN8PSnRUMeta?.name,
    path: "/sitemap.xml",
    component: component_45stubKi_45R4nsUaY4MPp_45b5Z_DjMpRGVKilt25GMPmN8PSnRU
  }
]